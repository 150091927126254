import React from "react";
import { Route, Routes } from "react-router-dom";
import ForgotPassword from "./pages/ForgotPassword/ForgotPassword";
import Signup from "./components/signup";
import Payment from "./pages/payment/Payment";
import InvoiceForm from "./pages/payment/InvoiceForm";
import Login from "./pages/login/Login";
import LoadingScreen from "./pages/payment/LoadingScreen";
import { Box } from "@mui/material";
import LanguageSelect from "./components/language-select";
import FooterLink from "./components/FooterLinks";

export default function RouterOutlet() {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          position: "absolute",
          justifyContent: "center",
          top: 30,
          right: 20,
        }}
      >
        <LanguageSelect />
      </Box>
      <div
        style={{
          minHeight: "80vh",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/login" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/signup/select-payment-method" element={<Payment />} />
          <Route path="/signup/pay-with-invoice" element={<InvoiceForm />} />
          <Route path="/setting-up-account" element={<LoadingScreen />} />
        </Routes>
      </div>
      <div style={{ marginTop: "1rem" }}>
        <FooterLink />
      </div>
    </>
  );
}
