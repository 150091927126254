import React from "react";

const loading = "./icons/loading.png";

export default function Loading() {
  return (
    <div style={{ textAlign: "left" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "286px",
        }}
      >
        <img src={loading} className="img-loading" alt="Loading" />
      </div>
    </div>
  );
}
