import { createContext, useState } from "react";

const ConfigContext = createContext({});

export const ConfigProvider = ({ children }) => {
  const [cardIframeLink, setCardIframeLink] = useState("");
  const [isDarkMode, setIsDarkMode] = useState(
    JSON.parse(localStorage.getItem("isDarkMode"))
  );
  const [companyData, setCompanyData] = useState({});
  return (
    <ConfigContext.Provider
      value={{
        isDarkMode,
        setIsDarkMode,
        companyData,
        setCompanyData,
        cardIframeLink,
        setCardIframeLink,
      }}
    >
      {children}
    </ConfigContext.Provider>
  );
};

export default ConfigContext;
