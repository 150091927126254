import { FormattedMessage } from "react-intl";

export const countriesList_en = [
  {
    name: "Austria",
    value: "AT",
  },
  {
    name: "Belgium",
    value: "BE",
  },
  {
    name: "Bulgaria",
    value: "BG",
  },
  {
    name: "Croatia",
    value: "HR",
  },
  {
    name: "Cyprus",
    value: "CY",
  },
  {
    name: "Czech Republic",
    value: "CZ",
  },
  {
    name: "Denmark",
    value: "DK",
  },
  {
    name: "Estonia",
    value: "EE",
  },
  {
    name: "Finland",
    value: "FI",
  },
  {
    name: "France",
    value: "FR",
  },
  {
    name: "Germany",
    value: "DE",
  },
  {
    name: "Greece",
    value: "EL",
  },
  {
    name: "Hungary",
    value: "HU",
  },
  {
    name: "Ireland",
    value: "IE",
  },
  {
    name: "Italy",
    value: "IT",
  },
  {
    name: "Latvia",
    value: "LV",
  },
  {
    name: "Lithuania",
    value: "LT",
  },
  {
    name: "Luxembourg",
    value: "LU",
  },
  {
    name: "Malta",
    value: "MT",
  },
  {
    name: "Netherlands",
    value: "NL",
  },
  // {
  //   name: "Northern Ireland",
  //   value: "NIR",
  // },
  {
    name: "Poland",
    value: "PL",
  },
  {
    name: "Portugal",
    value: "PT",
  },
  {
    name: "Romania",
    value: "RO",
  },
  {
    name: "Switzerland",
    value: "CH",
  },
  {
    name: "Slovakia",
    value: "SK",
  },
  {
    name: "Slovenia",
    value: "SI",
  },
  {
    name: "Spain",
    value: "ES",
  },
  {
    name: "Sweden",
    value: "SE",
  },
  {
    name: "United Kingdom",
    value: "GB",
  },
];

export const countriesList_de = [
  {
    name: "Belgien",
    value: "BE",
  },
  {
    name: "Bulgarien",
    value: "BG",
  },
  {
    name: "Dänemark",
    value: "DK",
  },
  {
    name: "Deutschland",
    value: "DE",
  },
  {
    name: "Estland",
    value: "EE",
  },
  {
    name: "Finnland",
    value: "FI",
  },
  {
    name: "Frankreich",
    value: "FR",
  },
  {
    name: "Griechenland",
    value: "EL",
  },
  {
    name: "Irland",
    value: "IE",
  },
  {
    name: "Italien",
    value: "IT",
  },
  {
    name: "Kroatien",
    value: "HR",
  },
  {
    name: "Lettland",
    value: "LV",
  },
  {
    name: "Litauen",
    value: "LT",
  },
  {
    name: "Luxemburg",
    value: "LU",
  },
  {
    name: "Malta",
    value: "MT",
  },
  {
    name: "Niederlande",
    value: "NL",
  },
  // {
  //   name: "Nordirland",
  //   value: "NIR",
  // },
  {
    name: "Österreich",
    value: "AT",
  },
  {
    name: "Polen",
    value: "PL",
  },
  {
    name: "Portugal",
    value: "PT",
  },
  {
    name: "Rumänien",
    value: "RO",
  },
  {
    name: "Schweden",
    value: "SE",
  },
  {
    name: "Schweiz",
    value: "CH",
  },
  {
    name: "Slowakei",
    value: "SK",
  },
  {
    name: "Slowenien",
    value: "SI",
  },
  {
    name: "Spanien",
    value: "ES",
  },
  {
    name: "Tschechien",
    value: "CZ",
  },
  {
    name: "Ungarn",
    value: "HU",
  },
  {
    name: "Vereinigtes Königreich",
    value: "GB",
  },
  {
    name: "Zypern",
    value: "CY",
  },
];

export const apiError = [
  {
    title: "somethingWentWrong",
    messages: [
      {
        text: <FormattedMessage id="yourRequestCouldNotBeProcessed" />,
        type: "error",
      },
    ],
  },
];
export const missingFields = [
  {
    title: "somethingWentWrong",
    messages: [
      {
        text: <FormattedMessage id="missingFields" />,
        type: "error",
      },
    ],
  },
];
