import { EcAuthContext } from "@eclear-ag/ec-auth";
import { Button, Divider, Typography } from "@mui/material";
import React, { useContext } from "react";
import { FormattedMessage } from "react-intl";

const Invite = () => {
  const { logout } = useContext(EcAuthContext);

  const gotoBackoffice = () => {
    const url = window.location;
    const env = url.hostname.split(".")[0];
    switch (env) {
      case "localhost":
        window.location.href = `${url.protocol}//dev-bo.eclear-solutions.com`;
        break;
      case "eclear-solutions":
        window.location.href = `${url.protocol}//bo.eclear-solutions.com`;
        break;
      default:
        window.location.href = `${url.protocol}//${env}-bo.eclear-solutions.com`;
        break;
    }
  };

  return (
    <div className="ec-box">
      <div
        style={{
          padding: "20px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography fontSize={16}>
          <FormattedMessage id="Tenant Creation Completed" />
        </Typography>
      </div>
      <Divider />
      <div
        style={{
          padding: "20px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        onClick={(e) => {
          e.preventDefault();
          logout();
          gotoBackoffice();
        }}
      >
        <Button>
          <FormattedMessage id="backToBackoffice" />
        </Button>
      </div>
    </div>
  );
};
export default Invite;
