import "./styles.css";
import { EcAuthContext } from "@eclear-ag/ec-auth";
import { AppBar, Box, ButtonBase } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

const Header = () => {
  const { getUserCompany, setCurrentUserStatus, resetUserData } =
    React.useContext(EcAuthContext);
  const navigate = useNavigate();
  console.log("getUserCompany: ", getUserCompany());
  return (
    <AppBar
      elevation={0}
      position="static"
      className="header"
      sx={{
        color: "transparent",
        backgroundColor: "transparent",
        borderTop: "8px solid",
        borderColor: `var(--${getUserCompany()})`,
        boxShadow: "unset",
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <ButtonBase
          aria-label="go to homepage"
          disableTouchRipple
          focusRipple
          onClick={(e) => {
            e.preventDefault();
            resetUserData();
            setCurrentUserStatus("");
            navigate(`/`);
          }}
          sx={{
            cursor: "pointer",
            display: "felx",
            alignSelf: "center",
            padding: "0px",
          }}
        >
          <img
            src={
              getUserCompany() !== "spot"
                ? window.location.origin +
                  "/logos/" +
                  getUserCompany() +
                  "-logo.png"
                : window.location.origin + "/logos/logo2.svg"
            }
            className={"app-logo"}
            alt="logo.svg"
          />
        </ButtonBase>
      </Box>
    </AppBar>
  );
};

export default Header;
