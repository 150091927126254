export const registerErrorMsg = [
  {
    title: "registerError",
    messages: [
      {
        text: "register.error",
        type: "error",
      },
    ],
  },
];
export const errorMsg = [
  {
    title: "somethingIsNotRight",
    messages: [
      {
        text: "email.error",
        type: "error",
      },
      {
        text: "password.error",
        type: "error",
      },
      {
        text: "password.capitalSmall",
        type: "error",
      },
      {
        text: "password.digit",
        type: "error",
      },
      {
        text: "password.specialCharacter",
        type: "error",
      },
      {
        text: "password.matching",
        type: "error",
      },
    ],
  },
];
