export const isAnyEmpty = (jsonObject, keysToIgnore) => {
  //This funcntion takes a json object and an array of keys to ignore
  //Example usage: isAnyEmpty(jsonObject, ["key1", "key2", "key3"])

  for (const key in jsonObject) {
    if (!keysToIgnore.includes(key)) {
      const value = jsonObject[key];
      if (value === null || value === undefined || value === "") {
        return true;
      }
    }
  }
  return false;
};

export function isJsonEmpty(json) {
  return Object.keys(json).length === 0;
}
