import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import { FormattedMessage } from "react-intl";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ECAxiosAPI, EcAuthContext } from "@eclear-ag/ec-auth";
import { URLS } from "../../configuration/urls";
import AutoFillContext from "../../services/auto-fill-signup";
import { useForm } from "react-hook-form";
import FormTextField from "../../components/_eform/form-elements/FormTextField";

const UserInfo = ({ handleNextScreen }) => {
  const {
    userData,
    user,
    productStatusInfos,
    getCompanyData,
    tokenFromLocalStorage,
  } = useContext(EcAuthContext);
  const navigate = useNavigate();
  const [isSaving, setIsSaving] = useState(false);
  const [savedUserInfo, setSavedUserInfo] = useState({});

  let [searchParams, setSearchParams] = useSearchParams();

  const searchParamsObj = Object.fromEntries(new URLSearchParams(searchParams));
  const isInvite = searchParams.get("type") === "invite";

  const completedPayment = productStatusInfos.some((item) => {
    return item.productType === "SPOT" && item.status === "COMPLETED";
  });

  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    getValues,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      firstName: "",
      lastName: "",
    },
  });

  useEffect(() => {
    var a = window.location.href;
    var b = a.substring(a.indexOf("?") + 1);
    if (!user) {
      navigate("/login?" + b);
    }
  }, [user]);

  const onSubmit = async (e) => {
    try {
      const res = await handleSubmitForm();
      if (res) {
        !completedPayment
          ? navigate("/signup/select-payment-method")
          : (window.location.href = "/spot/");
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    setSavedUserInfo({
      firstName: userData?.userInfo?.firstName,
      lastName: userData?.userInfo?.lastName,
    });
    if (userData.companyId) {
      reset({
        firstName: userData.userInfo.firstName,
        lastName: userData.userInfo.lastName,
      });
    }
  }, [userData]);

  const getUserInfo = async () => {
    await ECAxiosAPI.get(URLS.USER(tokenFromLocalStorage()?.sub))
      .then((res) => {
        setSavedUserInfo({
          firstName: res.firstName,
          lastName: res.lastName,
        });
        reset({
          firstName: res.firstName,
          lastName: res.lastName,
        });
      })
      .catch((err) => {
        return;
      });
  };

  useEffect(() => {
    isInvite && navigate("/");
    user?.accessToken?.payload?.sub &&
      userData?.companyDetails?.[0]?.merchantId &&
      getUserInfo();
  }, []);

  const { autoFillData } = useContext(AutoFillContext);
  useEffect(() => {
    Object.keys(autoFillData).length > 2 &&
      reset({
        firstName: autoFillData.firstName,
        lastName: autoFillData.lastName,
      });
  }, [autoFillData]);

  const handleSubmitForm = async () => {
    setIsSaving(true);
    if (JSON.stringify(savedUserInfo) !== JSON.stringify(getValues())) {
      const data = {
        ...getValues(),
        id: user.accessToken.payload.sub,
      };
      try {
        const merchant = await getCompanyData();
        await ECAxiosAPI.put(URLS.CREATE_USER_INFO(merchant.merchantId), data)
          .then((res) => {
            setIsSaving(false);
            !completedPayment
              ? navigate("/signup/select-payment-method")
              : (window.location.href = "/spot/");
          })
          .catch((err) => {
            setIsSaving(false);
          });
      } catch (error) {
        setIsSaving(false);
        return false;
      }
    } else if (JSON.stringify(savedUserInfo) === JSON.stringify(getValues())) {
      setIsSaving(false);
      !completedPayment
        ? navigate("/signup/select-payment-method")
        : (window.location.href = "/spot/");
    }
  };

  return (
    <Box
      component={"form"}
      onSubmit={handleSubmit(onSubmit)}
      className="ec-box"
      sx={{ borderRadius: "8px", overflow: "hidden" }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-end",
          p: "22px 20px 12px 20px",
        }}
      >
        <Box>
          <Typography fontWeight={500} fontSize={"20px"} component="p">
            <FormattedMessage id="userInformation" />
          </Typography>
          <Typography fontWeight={400} fontSize={"16px"} component="p">
            <FormattedMessage id="userInformation.subtitle" />
          </Typography>
        </Box>
      </Box>
      <Divider />
      <Box sx={{ p: "20px" }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <FormTextField
              width={6}
              errors={errors}
              control={control}
              label={"firstName"}
              required
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormTextField
              width={6}
              errors={errors}
              control={control}
              label={"lastName"}
              required
            />
          </Grid>
        </Grid>
        <Box sx={{ textAlign: "center", mt: "30px" }}>
          <Button
            disableElevation
            color="primary"
            variant="contained"
            fullWidth={true}
            id="finisherButton"
            sx={{
              fontSize: "16px",
              p: "12px",
              textTransform: "initial",
              mb: "20px",
            }}
            disabled={isSaving}
            type="submit"
          >
            {isSaving ? (
              <CircularProgress style={{ width: "28px", height: "28px" }} />
            ) : (
              <FormattedMessage id="continue" />
            )}
          </Button>

          <a
            onClick={() => {
              !isInvite
                ? handleNextScreen({ ...searchParamsObj, screen: "company" })
                : navigate("/signup");
            }}
          >
            <Button
              variant="text"
              style={{ opacity: "0.6" }}
              sx={{ fontSize: "12px", textTransform: "initial", mb: "16px" }}
            >
              <FormattedMessage id="back" />
            </Button>
          </a>
        </Box>
      </Box>
      <div>
        <Divider />
        <div style={{ padding: "20px 20px 0rem 20px", fontSize: "14px" }}>
          <span>
            <FormattedMessage id="required" />
          </span>
        </div>
        <Typography component="p" sx={{ p: "20px", m: 0 }}>
          **
          <FormattedMessage id="after90Days" />
        </Typography>
      </div>
    </Box>
  );
};

export default UserInfo;
