import { ECAxiosAPI } from "@eclear-ag/ec-auth";
import { URLS } from "../configuration/urls";

export const resetPassword = async (email) => {
  return await ECAxiosAPI.post(
    URLS.EC_AUTH_BE() + URLS.SEND_EMAIL_FORGOT_PASSWORD_CODE,
    email,
    {
      headers: {
        "Content-Type": "text/plain",
      },
    }
  )
    .then((res) => {
      return res || "success";
    })
    .catch((err) => {
      return err.response.data.message || "error";
    });
};

export const submitNewPassword = async (email, code, new_password) => {
  return await ECAxiosAPI.post(
    URLS.EC_AUTH_BE() + URLS.RESET_PASSWORD_WITH_CODE,
    {
      email: email,
      code,
      password: new_password,
    }
  )
    .then((res) => {
      return res || "success";
    })
    .catch((err) => {
      return err.response.data.message || "error";
    });
};

export const resendVerifyCode = async (username) => {
  return ECAxiosAPI.post(
    URLS.EC_AUTH_BE() + URLS.RESEND_VERIFY_EMAIL_BY_CODE,
    username,
    { headers: { "Content-Type": "text/plain" } }
  )
    .then((res) => {
      return res || "success";
    })
    .catch((err) => {
      return err.response.data.message || "error";
    });
};

export const register = async (username, password) => {
  return ECAxiosAPI.post(URLS.EC_AUTH_BE() + URLS.CREATE_USER, {
    username: username,
    email: username,
    password: password,
    channelPartnerId: window.location.hostname,
  })
    .then((res) => {
      return 200;
    })
    .catch((err) => {
      return 400;
    });
};
