export const emailSendSuccess = [
  {
    title: "verify.emailSent",
    messages: [
      {
        text: "verify.subtitle",
        type: "success",
      },
    ],
  },
];
export const emailErrorMessages = [
  {
    title: "email.criteria",
    messages: [
      {
        text: "email.error",
        type: "error",
      },
    ],
  },
];

export const maxAttemptError = [
  {
    title: "error",
    messages: [
      {
        text: "updatePassword.tooManyAttempts",
        type: "error",
      },
    ],
  },
];

export const noUserError = [
  {
    title: "error",
    messages: [
      {
        text: "updatePassword.noUser",
        type: "error",
      },
    ],
  },
];
export const invalidCodeError = [
  {
    title: "error",
    messages: [
      {
        text: "updatePassword.invalidCode",
        type: "error",
      },
    ],
  },
];

export const passwordSubmitError = [
  {
    title: "error",
    messages: [
      {
        text: "updatePassword.error",
        type: "error",
      },
    ],
  },
];

export const error404 = [
  {
    title: "error",
    messages: [
      {
        text: "emailSubmit.error",
        type: "error",
      },
    ],
  },
];

export const errorMsg = [
  {
    title: "somethingIsNotRight",
    messages: [
      {
        text: "password.error",
        type: "error",
      },
      {
        text: "password.capitalSmall",
        type: "error",
      },
      {
        text: "password.digit",
        type: "error",
      },
      {
        text: "password.specialCharacter",
        type: "error",
      },
      {
        text: "password.matching",
        type: "error",
      },
      {
        text: "code",
        type: "error",
      },
    ],
  },
];

export const registerErrorMsg = [
  {
    title: "registerError",
    messages: [
      {
        text: "register.error",
        type: "error",
      },
    ],
  },
];
