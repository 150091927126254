import { EcAuthContext } from "@eclear-ag/ec-auth";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate, useSearchParams } from "react-router-dom";
import ValidationMessage from "../../components/validationMessage";
import {
  error_saving,
  incorrect_vat_number,
  merchant_vat_id_already_used,
  oneOrMoreFieldsEmpty,
  user_does_not_exist,
} from "./validation_messages";
import AutoFillContext from "../../services/auto-fill-signup";
import {
  VatIdValidator,
  countriesList_de,
  countriesList_en,
} from "./countries_data";
import { ECAxiosAPI } from "@eclear-ag/ec-auth";
import { URLS } from "../../configuration/urls";
import ConfigContext from "../../services/config";
import FormTextField from "../../components/_eform/form-elements/FormTextField";
import { useForm } from "react-hook-form";
import FormDropDown from "../../components/_eform/form-elements/FormDropDown";
import { formattedMessage } from "../../configuration/helpers";

const CompanyInfo = ({ nextScreen, handleNextScreen }) => {
  const { user, userData, currentUserStatus, productStatusInfos } =
    useContext(EcAuthContext);
  let [searchParams, setSearchParams] = useSearchParams();

  const isInvite = searchParams.get("type") === "invite";

  const {
    handleSubmit,
    formState: { errors },
    control,
    watch,
    reset,
    getValues,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      vatId: "",
      companyName: "",
      channelPartner: "",
      legalForm: "",
      street: "",
      city: "",
      postalCode: "",
      country: "",
      houseNumber: "",
      phoneNumber: "",
    },
  });

  const vatId = watch("vatId");

  const [validationMessages, setValidationMessages] = useState([]);
  const [isValidateVisible, setIsValidateVisible] = useState(false);
  const [isSavingCompanyInfo, setIsSavingCompanyInfo] = useState(false);
  const [doesCompanyExist, setDoesCompanyExist] = useState(false);
  const [vatIdValidation, setVatIdValidation] = useState({});
  const navigate = useNavigate();
  const { autoFillData } = useContext(AutoFillContext);
  const { checkUserData } = useContext(EcAuthContext);
  const { setCompanyData, companyData } = useContext(ConfigContext);

  useEffect(() => {
    var a = window.location.href;
    var b = a.substring(a.indexOf("?") + 1);
    if (!user) {
      navigate("/login?" + b);
    }
  }, [user]);

  useEffect(() => {
    Object.keys(autoFillData).length > 2 &&
      reset({
        vatId: autoFillData.vatID,
        companyName: autoFillData.name,
        channelPartner: autoFillData.channelPartner,
        legalForm: autoFillData.legalForm,
        street: autoFillData.street,
        city: autoFillData.city,
        postalCode: autoFillData.postalCode,
        houseNumber: autoFillData.houseNumber,
        phoneNumber: autoFillData.phoneNumber,
        country: autoFillData.country,
      });
  }, [autoFillData]);

  useEffect(() => {
    const searchParamsObj = Object.fromEntries(
      new URLSearchParams(searchParams)
    );
    currentUserStatus === "COMPANY_INFO" &&
      productStatusInfos.length > 0 &&
      handleNextScreen({ ...searchParamsObj, screen: "user" });
  }, []);

  const loadCompanyData = async () => {
    await ECAxiosAPI.get(`${URLS.CREATE_MERCHANT}`, {
      headers: {
        userId: user.accessToken?.payload?.sub,
      },
    })
      .then((res) => {
        setCompanyData(res);
        reset({
          vatId: res.vatId,
          companyName: res.name,
          channelPartner: res.channelPartner,
          legalForm: res.legalForm,
          street: res.street,
          houseNumber: res.houseNumber,
          city: res.city,
          phoneNumber: res.phoneNumber,
          postalCode: res.postalCode,
          country: res.country,
        });
        setDoesCompanyExist(true);
        return true;
      })
      .catch((err) => {
        setDoesCompanyExist(false);
        return false;
      });
  };

  useEffect(() => {
    loadCompanyData();
  }, [user]);

  const onSubmit = async (e) => {
    userData.companyId === "" && (await checkUserData());
    setIsSavingCompanyInfo(true);
    const searchParamsObj = Object.fromEntries(
      new URLSearchParams(searchParams)
    );
    const payload = { ...getValues(), name: getValues("companyName") };
    doesCompanyExist
      ? await ECAxiosAPI.put(
          `${URLS.EDIT_MERCHANT(companyData.merchantId)}`,
          {
            ...payload,
            channelPartner: window.location.hostname,
            email: user.accessToken?.payload?.email,
            merchantId: userData.companyId || companyData.merchantId,
          },
          {
            headers: {
              userId: user.accessToken?.payload?.sub,
            },
          }
        )
          .then((res) => {
            setIsSavingCompanyInfo(false);
            handleNextScreen({ ...searchParamsObj, screen: nextScreen });
          })
          .catch((err) => {
            setIsSavingCompanyInfo(false);
            switch (err.response.data.message) {
              case "merchant_vat_id_already_used":
                setValidationMessages(merchant_vat_id_already_used);
                break;
              case "incorrect_vat_number":
                setValidationMessages(incorrect_vat_number);
                break;
              case "CountryCode does not represent EU country nor Northern Ireland!":
                setValidationMessages(error_saving);
                break;
              case err.response?.data?.message?.includes("User with id"):
                setValidationMessages(user_does_not_exist);
                break;
              default:
                setValidationMessages(error_saving);
                break;
            }
            setIsSavingCompanyInfo(false);
            setIsValidateVisible(true);
            return err.code;
          })
      : await ECAxiosAPI.post(
          `${URLS.CREATE_MERCHANT}`,
          {
            ...payload,
            channelPartner: window.location.hostname,
            email: user.accessToken?.payload?.email,
          },
          {
            headers: {
              userId: user.accessToken?.payload?.sub,
            },
          }
        )
          .then((res) => {
            setIsSavingCompanyInfo(false);
            handleNextScreen({ ...searchParamsObj, screen: nextScreen });
          })
          .catch((err) => {
            setIsSavingCompanyInfo(false);
            switch (err.response.data.message) {
              case "merchant_vat_id_already_used":
                setValidationMessages(merchant_vat_id_already_used);
                break;
              case "incorrect_vat_number":
                setValidationMessages(incorrect_vat_number);
                break;
              case "CountryCode does not represent EU country nor Northern Ireland!":
                setValidationMessages(error_saving);
                break;
              case err.response?.data?.message?.includes("User with id"):
                setValidationMessages(user_does_not_exist);
                break;
              case "Validation Error":
                const errorFields = err.response.data.subErrors?.map(
                  (item) => item.field
                );
                setValidationMessages(oneOrMoreFieldsEmpty(errorFields));
                break;
              default:
                setValidationMessages(error_saving);
                break;
            }
            setIsSavingCompanyInfo(false);
            setIsValidateVisible(true);
            return err.code;
          });
    setIsSavingCompanyInfo(false);
  };

  return (
    <Paper
      className="ec-box"
      elevation={0}
      sx={{
        borderRadius: "8px",
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-end",
          p: "22px 20px 12px 20px",
        }}
      >
        <Box>
          <Typography fontWeight={500} fontSize={"20px"} component="p">
            <FormattedMessage id="companyInformation" />
          </Typography>
          <Typography fontWeight={400} fontSize={"16px"} component="p">
            <FormattedMessage id="companyInformation.subtitle" />
          </Typography>
        </Box>
      </Box>
      <Divider />
      <form style={{ padding: "20px" }} onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FormTextField
              width={12}
              errors={errors}
              control={control}
              label={"vatId"}
              required
              validation={{
                vatIdValidation: (value) => {
                  let res = VatIdValidator(value);
                  setVatIdValidation(res);
                  return res.valid || false;
                },
              }}
              vatIdValidation={vatIdValidation}
              type="vat"
              value={vatId}
              disabled={doesCompanyExist}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormTextField
              width={6}
              errors={errors}
              control={control}
              label={"companyName"}
              required
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormTextField
              width={6}
              errors={errors}
              control={control}
              label={"legalForm"}
              required
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormTextField
              width={6}
              errors={errors}
              control={control}
              label={"street"}
              required
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormTextField
              width={6}
              errors={errors}
              control={control}
              label={"houseNumber"}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormTextField
              width={6}
              errors={errors}
              control={control}
              label={"postalCode"}
              required
              validation={{
                matchPattern: (value) => /^[a-zA-Z0-9]{5,}$/.test(value),
              }}
              validationMessage={<FormattedMessage id="invalid postalcode" />}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormTextField
              width={6}
              errors={errors}
              control={control}
              label={"city"}
              required
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormTextField
              width={6}
              errors={errors}
              control={control}
              label={"phoneNumber"}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormDropDown
              control={control}
              width={6}
              placeholder={<FormattedMessage id="country_placeholder" />}
              label={"country"}
              errors={errors}
              options={
                userData.lang === "en" ? countriesList_en : countriesList_de
              }
              required
            />
          </Grid>
        </Grid>
        <ValidationMessage
          visibility={isValidateVisible}
          setVisibility={setIsValidateVisible}
          messages={validationMessages}
        />
        <Box sx={{ textAlign: "center", mt: "30px" }}>
          <Button
            aria-label="Continue"
            disableElevation
            variant="contained"
            color="secondary"
            fullWidth={true}
            sx={{
              fontSize: "16px",
              p: "12px",
              textTransform: "initial",
              mb: "20px",
            }}
            disabled={isSavingCompanyInfo}
            type="submit"
          >
            {isSavingCompanyInfo ? (
              <CircularProgress style={{ width: "28px", height: "28px" }} />
            ) : (
              <FormattedMessage id="continue" />
            )}
          </Button>
          <Button
            aria-label="Back"
            variant="text"
            style={{ opacity: "0.6" }}
            sx={{ fontSize: "12px", textTransform: "initial", mb: "16px" }}
            onClick={() => {
              navigate("/");
            }}
          >
            <FormattedMessage id="back" />
          </Button>
        </Box>
      </form>
      {!isInvite && (
        <div className="ec-box">
          <Divider />
          <div style={{ padding: "20px 20px 0rem 20px", fontSize: "14px" }}>
            <span>
              <FormattedMessage id="required" />
            </span>
          </div>
          <Typography component="p" sx={{ p: "20px", m: 0 }}>
            **
            <FormattedMessage id="after90Days" />
          </Typography>
        </div>
      )}
    </Paper>
  );
};
export default CompanyInfo;
