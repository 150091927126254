import React from "react";
import { Box, Collapse, Typography, MenuItem, Select } from "@mui/material";
import { FormattedMessage } from "react-intl";
import DropDownArrowIcon from "../../../assets/icons/DropDownArrowIcon";
import { Controller } from "react-hook-form";

const FormDropDown = ({
  errors,
  label,
  required,
  width = 12,
  disabled,
  validation,
  validationMessage,
  placeholder = "",
  options = [],
  control,
}) => {
  return (
    <Box className={`widget-col-${width}`}>
      <div>
        <label>
          <Typography
            variant="subtitle2"
            component="span"
            sx={{ pb: 1, pl: 0, opacity: 1 }}
          >
            <FormattedMessage id={label} />
            {required ? "*" : ""}
          </Typography>
        </label>
      </div>
      <Controller
        name={label}
        control={control}
        rules={{ required: required }}
        render={({
          field: { onChange, onBlur, value, name, ref },
          fieldState: { invalid, isTouched, isDirty, error },
          formState,
        }) => {
          return (
            <Select
              fullWidth
              disableUnderline
              defaultValue={""}
              labelId={`select-${label}`}
              id={`simple-select-${label}`}
              IconComponent={(props) => (
                <DropDownArrowIcon
                  className={`material-icons ${props.className}`}
                  style={{ marginTop: "3px", marginRight: "4px" }}
                />
              )}
              label={label}
              name={label}
              disabled={disabled}
              ref={ref}
              variant="filled"
              sx={{
                ".MuiFilledInput-input": {
                  pt: "8px",
                },
                "&:after": {
                  borderBottomColor: "var(--Text-primary) !important",
                  borderRadius: "4px",
                },
                borderRadius: "4px",
                ".MuiSelect-select": {
                  borderRadius: "4px",
                },
                ".Mui-focused": {
                  borderRadius: "4px",
                },
              }}
              MenuProps={{
                sx: { maxHeight: "250px" },
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "center",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "center",
                },
              }}
              displayEmpty
              onChange={onChange}
              value={value}
              // {...register(label, {
              //   required: required ? true : false,
              //   validate: { ...validation },
              // })}
            >
              <MenuItem value="" key={"none"}>
                <span style={{ opacity: "0.6" }}> {placeholder}</span>
              </MenuItem>
              {options?.map((option, index) => (
                <MenuItem value={option.value} key={index}>
                  {option.name}
                </MenuItem>
              ))}
            </Select>
          );
        }}
      />

      <div
        style={{
          fontWeight: 400,
          fontSize: "14px",
        }}
      >
        <Collapse in={errors[label]?.type === "required"}>
          <span className="red">
            <FormattedMessage id={label} />{" "}
            <FormattedMessage id={"isRequired"} />
          </span>
        </Collapse>

        <Collapse in={errors[label]?.type === "matchPattern"}>
          <span className="red">{validationMessage}</span>
        </Collapse>
      </div>
    </Box>
  );
};

export default FormDropDown;
