export const resendSuccessMsg = [
  {
    title: "verify.emailSent",
    messages: [
      {
        text: "verify.emailSentMsg",
        type: "success",
      },
    ],
  },
];

//Message that will be shown when the code resend fails for any reason
//Refer to translation files to see the exact message that will be displayed
export const resendFailMsg = [
  {
    title: "verify.resendFail",
    messages: [
      {
        text: "verify.resendFailMsg",
        type: "error",
      },
    ],
  },
];
//Message that will be shown when the entereds code is invalid
//Refer to translation files to see the exact message that will be displayed
export const invalidCodeMsg = [
  {
    title: "verify.invalidCode",
    messages: [
      {
        text: "verify.invalidCodeMsg",
        type: "error",
      },
    ],
  },
];
//Message that will be shown when the verficiation code request limit is exceeded
//Refer to translation files to see the exact message that will be displayed
export const codeLimitExceedMsg = [
  {
    title: "verify.codeLimitExceed",
    messages: [
      {
        text: "verify.codeLimitExceedMsg",
        type: "error",
      },
    ],
  },
];

export const invalidEmail = [
  {
    title: "verify.invalidEmail",
    messages: [
      {
        text: "verify.invalidEmailMsg",
        type: "error",
      },
    ],
  },
];
