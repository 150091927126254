export const error_message = (text) => [
  {
    title: "error",
    messages: [
      {
        text: text,
        type: "error",
      },
    ],
  },
];
