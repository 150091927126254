import messages_en from "../translations/en.json";
import messages_de from "../translations/de.json";

const messages = {
  en: messages_en,
  de: messages_de,
};

const localLang = localStorage.getItem("lang");

export const formattedMessage = (lang, message) => {
  return messages[lang || localLang][message];
};
