import "./assets/styles/theme.css";
import React, { useContext, useEffect } from "react";
import ReactDOM from "react-dom/client";
import { EcAuthContext, EcAuthProvider, EcLayout } from "@eclear-ag/ec-auth";
import "@eclear-ag/ec-auth/dist/index.css";
import "./assets/styles/index.css";
import { IntlProvider } from "react-intl";
import { BrowserRouter, useNavigate } from "react-router-dom";
import { AutoFillProvider } from "./services/auto-fill-signup";

import "./assets/styles/onboarding.css";
import RouterOutlet from "./router-outlet";

import ConfigContext, { ConfigProvider } from "./services/config";

import { SnackbarProvider } from "notistack";
import Header from "./components/Header/Header";

const MainCheck = ({ currentUserStatus }) => {
  const { productStatusInfos } = useContext(EcAuthContext);

  const packageJSON = require("../package.json");
  console.log("ec-main v", packageJSON.version);

  const navigate = useNavigate();

  let a = window.location.href;
  const switchRoute = () => {
    let b = a.includes("?") ? a.substring(a.indexOf("?") + 1) : "";

    switch (currentUserStatus) {
      case "REGISTER":
        navigate(`/signup?screen=company&${b}`);
        break;
      case "VERIFIED":
        navigate(`/signup?screen=company&${b}`);
        break;
      case "COMPANY_INFO":
        navigate(`/signup?screen=user&${b}`);
        break;
      case "USER_INFO":
        navigate(`/signup/select-payment-method`);
        break;
      case "COMPLETED":
        if (window.location.host === "localhost:3000") {
          return;
        }
        productStatusInfos.length === 0
          ? navigate(`/signup/select-payment-method`)
          : (window.location.href = "/spot/");
        break;
      case "DASHBOARD":
        navigate(`/signup/select-payment-method`);
        break;
      case "PAYMENT_METHOD_PENDING":
        navigate(`/signup/select-payment-method`);
        break;
      case "PAYMENT_METHOD_CONFIRMED":
        if (window.location.host === "localhost:3000") {
          return;
        }
        window.location.href = "/spot/";
        break;
      default:
        navigate(`/`);
        break;
    }
  };

  return <React.Fragment>{switchRoute()}</React.Fragment>;
};

const BodyIntl = () => {
  const { userData, currentUserStatus, setCurrentUserStatus, resetUserData } =
    React.useContext(EcAuthContext);

  const { isDarkMode } = React.useContext(ConfigContext);

  const navigate = useNavigate();

  useEffect(() => {
    if (
      window.location.href ===
      window.location.protocol + "//" + window.location.host
    ) {
      resetUserData();
      setCurrentUserStatus("");
      navigate(`/`);
    }
  }, [window.location.href]);

  useEffect(() => {
    if (isDarkMode) {
      document.body.classList.add("dark");
    } else {
      document.body.classList.remove("dark");
    }
    document.body.setAttribute("data-theme", isDarkMode ? "dark" : "light");
  }, [isDarkMode]);

  return (
    <IntlProvider
      locale={userData.lang}
      messages={require(`../src/translations/${userData.lang.toLowerCase()}.json`)}
    >
      <EcLayout
        header={<Header />}
        main={<MainCheck currentUserStatus={currentUserStatus} />}
        loginScreen={<RouterOutlet />}
      />
    </IntlProvider>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter>
    <EcAuthProvider>
      <AutoFillProvider>
        <ConfigProvider>
          <SnackbarProvider
            preventDuplicate
            autoHideDuration={3000}
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <BodyIntl />
          </SnackbarProvider>
        </ConfigProvider>
      </AutoFillProvider>
    </EcAuthProvider>
  </BrowserRouter>
);
