import React, { createContext, useEffect, useState } from "react";

const AutoFillContext = createContext({});

// params required for auto fill
/*
 * email=example@email.com
 * vatid=DE12345678
 * companyname=testcompany
 * legalform=AG
 * street=boulevard
 * housenumber=42
 * postcode=12345
 * city=Berlin
 * phone=+4912384829
 * firstname=Melina
 * lastname=Musterfrau
 */

export const AutoFillProvider = ({ children }) => {
    const [autoFillData, setAutoFillData] = useState({});

    // read url params if any
    const initURLParams = () => {
        let autoFillObj = {};

        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const entries = urlParams.entries();

        const modifyURLParams = (param) => {
            switch (param) {
                case "vatid":
                    return "vatID";
                case "companyname":
                    return "name";
                case "legalform":
                    return "legalForm";
                case "housenumber":
                    return "houseNumber";
                case "postcode":
                    return "postalCode";
                case "firstname":
                    return "firstName";
                case "lastname":
                    return "lastName";
                case "phone":
                    return "phoneNumber";
                default:
                    return param;
            }
        };

        for (const entry of entries) {
            autoFillObj[modifyURLParams(entry[0])] = entry[1];
            //   autoFillObj[entry[0]] = entry[1];
        }
        setAutoFillData(autoFillObj);
    };

    useEffect(() => {
        initURLParams();
    }, []);

    return (
        <AutoFillContext.Provider
            value={{
                autoFillData,
            }}
        >
            {children}
        </AutoFillContext.Provider>
    );
};

export default AutoFillContext;
