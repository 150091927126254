import { MenuItem, Select, Box } from "@mui/material";
import { EcAuthContext } from "@eclear-ag/ec-auth";
import React, { useContext } from "react";
import DropDownArrowIcon from "../assets/icons/DropDownArrowIcon";
import { formattedMessage } from "../configuration/helpers";

export default function LanguageSelect(props) {
  const { userData, updateLang } = useContext(EcAuthContext);

  return (
    <Select
      style={{
        ...props.style,
        width: "35px",
        marginTop: "5px",
      }}
      className="language-select"
      title={formattedMessage(userData.lang, "Select language")}
      size="small"
      variant="standard"
      disableUnderline
      value={userData.lang}
      onChange={(e) => {
        updateLang(e.target.value);
      }}
      inputProps={{
        "aria-label": "Select language",
        id: "language-select",
      }}
      IconComponent={(props) => (
        <DropDownArrowIcon
          className={`material-icons ${props.className}`}
          style={{ marginTop: "3px" }}
        />
      )}
    >
      <MenuItem
        value="de"
        aria-label="German"
        title={formattedMessage(userData.lang, "German")}
      >
        <Box
          sx={{
            width: "16px",
            height: "16px",
            borderRadius: "50%",
            overflow: "hidden",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <svg
            width="25"
            height="25"
            viewBox="0 0 25 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_7434_505107)">
              <g clip-path="url(#clip1_7434_505107)">
                <path
                  d="M0.714355 16.4985H24.7144V24.5001H0.714355V16.4985Z"
                  fill="#FFCE00"
                />
                <path
                  d="M0.714355 0.500122H24.7144V8.50168H0.714355V0.500122Z"
                  fill="black"
                />
                <path
                  d="M0.714355 8.50171H24.7144V16.4986H0.714355V8.50171Z"
                  fill="#DD0000"
                />
              </g>
            </g>
            <defs>
              <clipPath id="clip0_7434_505107">
                <rect
                  x="0.714355"
                  y="0.500122"
                  width="24"
                  height="24"
                  rx="12"
                  fill="white"
                />
              </clipPath>
              <clipPath id="clip1_7434_505107">
                <rect
                  width="24"
                  height="24"
                  fill="white"
                  transform="translate(0.714355 0.500122)"
                />
              </clipPath>
            </defs>
          </svg>
        </Box>
      </MenuItem>
      <MenuItem
        value="en"
        aria-label="English"
        title={formattedMessage(userData.lang, "English")}
      >
        {" "}
        <Box
          sx={{
            width: "16px",
            height: "16px",
            borderRadius: "50%",
            overflow: "hidden",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <svg
            width="25"
            height="25"
            viewBox="0 0 25 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#jo6u4zu32a)">
              <g clip-path="url(#xxlrlupd5b)">
                <path d="M.429.667h24v24h-24v-24z" fill="#012169" />
                <path
                  d="M24.429.667v3l-8.906 9 8.906 8.765v3.235h-3.14l-8.954-8.813-8.719 8.813H.43v-3.188l8.718-8.765L.43 4.134V.667h2.906l9 8.812L21.054.667h3.375z"
                  fill="#fff"
                />
                <path
                  d="m9.054 15.854.515 1.594-7.172 7.219H.43v-.141l8.625-8.672zm5.812-.562 2.532.375 7.03 6.89v2.11l-9.562-9.375zM24.43.667l-9 9.187-.188-2.062L22.273.667h2.156zm-24 .047 9.047 8.859-2.766-.375L.429 2.963V.713z"
                  fill="#C8102E"
                />
                <path
                  d="M8.679.667v24h7.5v-24h-7.5zm-8.25 8.25v7.5h24v-7.5h-24z"
                  fill="#fff"
                />
                <path
                  d="M.429 10.417v4.5h24v-4.5h-24zm9.75-9.75v24h4.5v-24h-4.5z"
                  fill="#C8102E"
                />
              </g>
            </g>
            <defs>
              <clipPath id="jo6u4zu32a">
                <rect
                  x=".429"
                  y=".667"
                  width="24"
                  height="24"
                  rx="12"
                  fill="#fff"
                />
              </clipPath>
              <clipPath id="xxlrlupd5b">
                <path
                  fill="#fff"
                  transform="translate(.429 .667)"
                  d="M0 0h24v24H0z"
                />
              </clipPath>
            </defs>
          </svg>
        </Box>
      </MenuItem>
    </Select>
  );
}
