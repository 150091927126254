const DropDownArrowIcon = ({ className, style }) => {
  return (
    <svg
      className={className}
      style={style}
      width="12"
      height="8"
      viewBox="0 0 12 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.16941 0.818093C1.75959 0.376608 1.06081 0.376988 0.651476 0.818919C0.283888 1.21578 0.283887 1.82876 0.651476 2.22562L5.26636 7.20794C5.66213 7.63523 6.33787 7.63523 6.73364 7.20794L11.3485 2.22561C11.7161 1.82876 11.7161 1.21577 11.3485 0.818918C10.9392 0.376987 10.2404 0.376607 9.83059 0.818092L6 4.94467L2.16941 0.818093Z"
        className="svg-icon"
      />
    </svg>
  );
};

export default DropDownArrowIcon;
