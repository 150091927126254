import { ECAxiosAPI, EcAuthContext } from "@eclear-ag/ec-auth";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import ReactInputVerificationCode from "react-input-verification-code";
import { FormattedMessage } from "react-intl";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import ValidationMessage from "../../components/validationMessage";
import {
  codeLimitExceedMsg,
  invalidCodeMsg,
  invalidEmail,
  resendSuccessMsg,
} from "./validation_messages";
import { URLS } from "../../configuration/urls";
import { resendVerifyCode } from "../../services/register";

const verifyButtonInit = {
  isLoading: false,
  isDisabled: true,
};

const Verify = ({ handleNextScreen }) => {
  const { registerInfo, signin, currentUserStatus } = useContext(EcAuthContext);
  const { authUser } = useContext(EcAuthContext);

  const theme = useTheme();
  const isWidthDown920 = useMediaQuery(theme.breakpoints.down(920));

  let [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const email = searchParams.get("email");
  // setVerifyCode
  const [code, setCode] = useState("");

  const [verifyButtonState, setVerifyButtonState] = useState(verifyButtonInit);

  // show resend email validation message
  const [validationMessages, setValidationMessages] =
    useState(resendSuccessMsg);
  const [isValidateVisible, setIsValidateVisible] = useState(false);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setIsValidateVisible(false);
    }, 5000);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [isValidateVisible]);

  // Verify account
  const verifyUserAccount = async (username, code) => {
    setIsValidateVisible(false);
    setVerifyButtonState({ isLoading: true, isDisabled: true });

    await ECAxiosAPI.post(URLS.EC_AUTH_BE() + URLS.VERIFY_EMAIL_BY_CODE, {
      email: username,
      code,
    })
      .then(async () => {
        await signin(registerInfo.username, registerInfo.password);
        await authUser();
      })
      .then(() => {
        const searchParamsObj = Object.fromEntries(
          new URLSearchParams(searchParams)
        );
        handleNextScreen({
          ...searchParamsObj,
          screen: currentUserStatus === "COMPANY_INFO" ? "user" : "company",
        });
      })
      .catch(() => {
        setValidationMessages(invalidCodeMsg);
        setIsValidateVisible(true);
        setVerifyButtonState(verifyButtonInit);
      });
  };

  // resend code
  const resendVerifyUserCode = async () => {
    //Always hide the validation messages before making api calls. This is to ensure that the user does not get confused by all the validation messages.
    setIsValidateVisible(false);

    const res = await resendVerifyCode(email);
    if (res) {
      //If api call is successful, show success message.
      setValidationMessages(resendSuccessMsg);
      setIsValidateVisible(true);

      switch (res) {
        //A switch to decide what message to show in which scenario. We're taking in the .__type key from the reponse and applying a switch on it
        case "LimitExceededException":
          setValidationMessages(codeLimitExceedMsg);
          setIsValidateVisible(true);
          break;
        case "email_not_found":
          setValidationMessages(invalidEmail);
          setIsValidateVisible(true);
          break;
        default:
          setValidationMessages(resendSuccessMsg);
          setIsValidateVisible(true);
          break;
      }
    }
  };

  useEffect(() => {
    currentUserStatus === "UNVERIFIED" && resendVerifyUserCode();
  }, []);

  return (
    <div className="ec-box" style={{ borderRadius: "8px" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-end",
          p: "22px 20px 12px 20px",
        }}
      >
        <Box>
          <Typography fontWeight={500} fontSize={"20px"} component="p">
            <FormattedMessage id="verify.title" />
          </Typography>
          <Typography fontWeight={400} fontSize={"16px"} component="p">
            <FormattedMessage id="verify.subtitle" />
          </Typography>
        </Box>
      </Box>
      <Divider />
      <Box
        component="form"
        sx={{ p: "20px 20px 0 20px" }}
        onSubmit={(e) => {
          e.preventDefault();
          verifyUserAccount(email, code);
        }}
      >
        <Typography
          variant="subtitle2"
          component="p"
          sx={{ pb: 1, pl: 0, mb: "12px" }}
        >
          <FormattedMessage id="verify.fieldTitle" />
        </Typography>

        <Box className={`verification-input ${isWidthDown920 && "small"}`}>
          <ReactInputVerificationCode
            aria-label="Verification Code"
            placeholder="-"
            onCompleted={(code) => {
              const verifyCode = code.replaceAll("-", "");
              if (verifyCode.length > 5) {
                setCode(verifyCode);
                setVerifyButtonState({ isLoading: false, isDisabled: false });
              }
            }}
            length={6}
          />
        </Box>

        <ValidationMessage
          visibility={isValidateVisible}
          setVisibility={setIsValidateVisible}
          messages={validationMessages}
        />

        <Typography
          component="p"
          sx={{ mt: "20px", fontSize: "14px", mb: "30px" }}
        >
          <FormattedMessage id="verify.getEmail" />
          &nbsp;
          <strong
            style={{ textDecoration: "underline", cursor: "pointer" }}
            onClick={resendVerifyUserCode}
          >
            <FormattedMessage id="verify.here" />
          </strong>
          &nbsp;
          <FormattedMessage id="verify.resend" />
        </Typography>

        <Box sx={{ textAlign: "center" }}>
          <Button
            aria-label="Verify"
            disableElevation
            color="primary"
            variant="contained"
            fullWidth={true}
            sx={{
              fontSize: "16px",
              p: "12px",
              textTransform: "initial",
              mb: "20px",
            }}
            type="submit"
            disabled={verifyButtonState.isDisabled}
          >
            {verifyButtonState.isLoading ? (
              <CircularProgress style={{ width: "28px", height: "28px" }} />
            ) : (
              <FormattedMessage id="verify" />
            )}
          </Button>
          <Button
            aria-label="Cancel"
            onClick={() => {
              navigate("/");
            }}
            variant="text"
            style={{ opacity: "0.6" }}
            sx={{ fontSize: "12px", textTransform: "initial", mb: "16px" }}
          >
            <FormattedMessage id="cancel" />
          </Button>
        </Box>
      </Box>
    </div>
  );
};

export default Verify;
