export const countriesList_en = [
  {
    name: "Austria",
    value: "AT",
  },
  {
    name: "Belgium",
    value: "BE",
  },
  {
    name: "Bulgaria",
    value: "BG",
  },
  {
    name: "Croatia",
    value: "HR",
  },
  {
    name: "Cyprus",
    value: "CY",
  },
  {
    name: "Czech Republic",
    value: "CZ",
  },
  {
    name: "Denmark",
    value: "DK",
  },
  {
    name: "Estonia",
    value: "EE",
  },
  {
    name: "Finland",
    value: "FI",
  },
  {
    name: "France",
    value: "FR",
  },
  {
    name: "Germany",
    value: "DE",
  },
  {
    name: "Greece",
    value: "EL",
  },
  {
    name: "Hungary",
    value: "HU",
  },
  {
    name: "Ireland",
    value: "IE",
  },
  {
    name: "Italy",
    value: "IT",
  },
  {
    name: "Latvia",
    value: "LV",
  },
  {
    name: "Lithuania",
    value: "LT",
  },
  {
    name: "Luxembourg",
    value: "LU",
  },
  {
    name: "Malta",
    value: "MT",
  },
  {
    name: "Netherlands",
    value: "NL",
  },
  // {
  //   name: "Northern Ireland",
  //   value: "NIR",
  // },
  {
    name: "Poland",
    value: "PL",
  },
  {
    name: "Portugal",
    value: "PT",
  },
  {
    name: "Romania",
    value: "RO",
  },
  {
    name: "Switzerland",
    value: "CH",
  },
  {
    name: "Slovakia",
    value: "SK",
  },
  {
    name: "Slovenia",
    value: "SI",
  },
  {
    name: "Spain",
    value: "ES",
  },
  {
    name: "Sweden",
    value: "SE",
  },
  {
    name: "United Kingdom",
    value: "GB",
  },
];

export const countriesList_de = [
  {
    name: "Belgien",
    value: "BE",
  },
  {
    name: "Bulgarien",
    value: "BG",
  },
  {
    name: "Dänemark",
    value: "DK",
  },
  {
    name: "Deutschland",
    value: "DE",
  },
  {
    name: "Estland",
    value: "EE",
  },
  {
    name: "Finnland",
    value: "FI",
  },
  {
    name: "Frankreich",
    value: "FR",
  },
  {
    name: "Griechenland",
    value: "EL",
  },
  {
    name: "Irland",
    value: "IE",
  },
  {
    name: "Italien",
    value: "IT",
  },
  {
    name: "Kroatien",
    value: "HR",
  },
  {
    name: "Lettland",
    value: "LV",
  },
  {
    name: "Litauen",
    value: "LT",
  },
  {
    name: "Luxemburg",
    value: "LU",
  },
  {
    name: "Malta",
    value: "MT",
  },
  {
    name: "Niederlande",
    value: "NL",
  },
  // {
  //   name: "Nordirland",
  //   value: "NIR",
  // },
  {
    name: "Österreich",
    value: "AT",
  },
  {
    name: "Polen",
    value: "PL",
  },
  {
    name: "Portugal",
    value: "PT",
  },
  {
    name: "Rumänien",
    value: "RO",
  },
  {
    name: "Schweden",
    value: "SE",
  },
  {
    name: "Schweiz",
    value: "CH",
  },
  {
    name: "Slowakei",
    value: "SK",
  },
  {
    name: "Slowenien",
    value: "SI",
  },
  {
    name: "Spanien",
    value: "ES",
  },
  {
    name: "Tschechien",
    value: "CZ",
  },
  {
    name: "Ungarn",
    value: "HU",
  },
  {
    name: "Vereinigtes Königreich",
    value: "GB",
  },
  {
    name: "Zypern",
    value: "CY",
  },
];

export const VatIdValidator = (vatId) => {
  const vatIdRegEx = {
    AT: {
      country: "Austria",
      regex: /^ATU\d{8}$/,
    },
    BE: {
      country: "Belgium",
      regex: /^BE\d{10}$/,
    },
    BG: {
      country: "Bulgaria",
      regex: /^BG\d{9,10}$/,
    },
    HR: {
      country: "Croatia",
      regex: /^HR\d{11}$/,
    },
    CY: {
      country: "Cyprus",
      regex: /^CY\d{8}[A-Z]$/,
    },
    CZ: {
      country: "Czech Republic",
      regex: /^CZ\d{8,10}$/,
    },
    DK: {
      country: "Denmark",
      regex: /^DK\d{8}$/,
    },
    EE: {
      country: "Estonia",
      regex: /^EE\d{9}$/,
    },
    FI: {
      country: "Finland",
      regex: /^FI\d{8}$/,
    },
    FR: {
      country: "France",
      regex: /^FR[A-Z0-9]{2}\d{9}$/,
    },
    DE: {
      country: "Germany",
      regex: /^DE\d{9}$/,
    },
    EL: {
      country: "Greece",
      regex: /^EL\d{9}$/,
    },
    HU: {
      country: "Hungary",
      regex: /^HU\d{8}$/,
    },
    IE: {
      country: "Ireland",
      regex: /^IE\d[A-Z0-9+*]\d{5}[A-Z]{1,2}$/,
    },
    // NIR: {
    //   country: "Northern Ireland",
    //   regex: /^XI\d{2,12}$/,
    // },
    IT: {
      country: "Italy",
      regex: /^IT\d{11}$/,
    },
    LV: {
      country: "Latvia",
      regex: /^LV\d{11}$/,
    },
    LT: {
      country: "Lithuania",
      regex: /^LT(\d{9}|\d{12})$/,
    },
    LU: {
      country: "Luxembourg",
      regex: /^LU\d{8}$/,
    },
    MT: {
      country: "Malta",
      regex: /^MT\d{8}$/,
    },
    PL: {
      country: "Poland",
      regex: /^PL\d{10}$/,
    },
    PT: {
      country: "Portugal",
      regex: /^PT\d{9}$/,
    },
    RO: {
      country: "Romania",
      regex: /^RO\d{2,12}$/,
    },
    SK: {
      country: "Slovakia",
      regex: /^SK\d{10}$/,
    },
    SI: {
      country: "Slovenia",
      regex: /^SI\d{8}$/,
    },
    // ES: {
    //   country: "Spain",
    //   regex: /^ES([A-Z0-9])([A-Z]+|[0-9]{7})(?<!\1)\1$/,
    // },
    SE: {
      country: "Sweden",
      regex: /^SE\d{12}$/,
    },
    NL: {
      country: "Netherlands",
      regex: /^NL\d{9}B\d{2}$/,
    },
    GB: {
      country: "United Kingdom",
      regex: /^GB[A-Za-z0-9]{1,13}$/,
    },
    CH: {
      country: "Switzerland",
      regex: /^CH[A-Za-z0-9]{1,13}$/,
    },
  };

  if (vatId && vatId.substring(0, 2) === "ES") {
    const xAfterEs = vatId.substring(2, 3);
    const xAfterEs_type = /\d/.test(xAfterEs) ? "number" : "alphabet";
    const lastChar = vatId.substring(10);
    const lastChar_type = /\d/.test(lastChar) ? "number" : "alphabet";
    const compareFirstLast = (first_type, last_type) => {
      if (first_type === "number" && last_type === "number") {
        return false;
      }
      return true;
    };
    if (
      vatId.substring(0, 2) === "ES" &&
      xAfterEs.length === 1 &&
      /^\d{7}$/.test(vatId.substring(3, 10)) &&
      lastChar.length === 1 &&
      compareFirstLast(xAfterEs_type, lastChar_type)
    ) {
      return {
        valid: true,
        country: "Spain",
      };
    }
  }

  let validCountry = null;
  for (const { country, regex } of Object.values(vatIdRegEx)) {
    if (regex.test(vatId)) {
      validCountry = country;
      break;
    }
  }
  if (validCountry) {
    return {
      valid: true,
      country: validCountry,
    };
  } else {
    return {
      valid: false,
      country: "",
    };
  }
};
