import "../assets/styles/signup.css";
import React, { useRef, useEffect } from "react";
import { Box } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";

import CompanyInfo from "../pages/CompanyInfo/CompanyInfo";
import UserInfo from "../pages/UserInfo/UserInfo";
import { EcAuthContext } from "@eclear-ag/ec-auth";

import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Verify from "../pages/Verify/Verify";
import Register from "../pages/register/Register";
import Invite from "../pages/Invite/Invite";

export default function Signup() {
  let [searchParams, setSearchParams] = useSearchParams();
  const screens = [
    "product",
    "register",
    "verify",
    "company",
    "invitecomplete",
    "user",
  ];
  const initScreen = screens.find(
    (screen) => screen === searchParams.get("screen")
  );
  const [screen, setScreen] = React.useState(initScreen || "register");
  const isInvite = searchParams.get("type") === "invite";
  const renderCountRef = useRef(0);
  useEffect(() => {
    renderCountRef.current += 1;
  });

  React.useEffect(() => {
    const screen = screens.find((s) => s === searchParams.get("screen"));
    setScreen(screen || "register");
  }, [searchParams]);

  const { user } = React.useContext(EcAuthContext);
  const navigate = useNavigate();
  React.useEffect(() => {
    let a = window.location.href;
    let b = a.substring(a.indexOf("?") + 1);
    let paramLength = a.indexOf("?") + 1;
    if (!user) {
      paramLength === 0 ? navigate("/login") : navigate(`/login?${b}`);
      setScreen("register");
    }
  }, [user]);

  const handleNextScreen = (searchParamsObj) => {
    setSearchParams(searchParamsObj);
  };

  const theme = useTheme();
  const isWidthDown920 = useMediaQuery(theme.breakpoints.down(920));
  const isWidthDown600 = useMediaQuery(theme.breakpoints.down(600));

  return (
    <Box
      style={{
        borderRadius: "8px",
        maxWidth:
          screen === "register"
            ? "100%"
            : isWidthDown600
            ? "90%"
            : isWidthDown920
            ? "70%"
            : "60%",
        minWidth: screen === "company" && isInvite && "60%",
      }}
      component="div"
    >
      {screen === "register" && (
        <Register nextScreen={screens[2]} handleNextScreen={handleNextScreen} />
      )}

      {screen === "verify" && (
        <Verify nextScreen={screens[3]} handleNextScreen={handleNextScreen} />
      )}

      {screen === "company" && (
        <CompanyInfo
          nextScreen={isInvite ? screens[4] : screens[5]}
          handleNextScreen={handleNextScreen}
        />
      )}

      {screen === "invitecomplete" && <Invite />}

      {screen === "user" && <UserInfo handleNextScreen={handleNextScreen} />}
    </Box>
  );
}
