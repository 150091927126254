export const errorMessages = [
  {
    title: "somethingIsNotRight",
    messages: [
      {
        text: "email.error",
        type: "error",
      },
      {
        text: "password.error",
        type: "error",
      },
    ],
  },
];
