const SPOT_BASE_URL = () => {
  switch (true) {
    case window.location.host.includes("localhost"):
      return "https://dev.eclear-solutions.com/ec-spot-be/v1/";
    case window.location.host.includes("ref"):
      return "https://eclear-solutions.com/ec-spot-be/v1/";
    default:
      return `https://${window.location.host}/ec-spot-be/v1/`;
  }
};

const EC_AUTH_BE_V2 = () => {
  return window.location.host.includes("localhost")
    ? `https://dev.eclear-solutions.com/ec-authorization-be/v2/`
    : `https://${window.location.host}/ec-authorization-be/v2/`;
};

export const URLS = {
  // ______________________BASE URLS______________________
  ECLEAR_DEV: "https://dev.eclear-solutions.com/ec-spot-be/v1/",
  ECLEAR_PROD: "https://eclear-solutions.com/ec-spot-be/v1/",
  DHL_DEV: "https://dhl-dev.eclear-solutions.com/ec-spot-be/v1/",
  JERA_DEV: "https://dev-jera.eclear-solutions.com/ec-spot-be/v1/",
  WORLDLINE_DEV: "https://dev-worldline.eclear-solutions.com/ec-spot-be/v1/",
  PAYONE_PROD: "https://payone.eclear-solutions.com/login",
  PAYONE_DEV: "https://dev-payone.eclear-solutions.com/login",
  EC_MEDIA_DEV: "https://dev.eclear-solutions.com/ec-media-be/v1/media/",
  CREATE_USER: "user-manager/register",

  EC_SPOT_BE: () => {
    return SPOT_BASE_URL();
  },
  EC_AUTH_BE: () => {
    return window.location.host.includes("localhost")
      ? `https://dev.eclear-solutions.com/ec-authorization-be/v1/`
      : `https://${window.location.host}/ec-authorization-be/v1/`;
  },

  // ______________________SPOT ENDPOINTS______________________
  COMPANY: SPOT_BASE_URL() + "company",
  USER_STATUS: "user/status/",
  USER_STATUS_VALUES: "user/status/values",
  RESET_PASSWORD_WITH_CODE: "user-manager/recover-password",

  // ______________________PAYMENTS & SUBSCRIPTIONS______________________
  PRODUCT_TYPE_SPOT: "product/find-by-product-type/SPOT",
  GENERATE_CHECKOUT_SESSION:
    "subscription-management/generate-checkout-session",
  CREATE_INVOICE_SUBSCRIPTION:
    "subscription-management/create-invoice-subscription",
  SUBSCRIPTION: (merchant, shop) => {
    return `merchant/${merchant}/shop/${shop}/subscription`;
  },
  CANCEL_SUBSCRIPTION: (subscriptionId) => {
    return `subscription-management/cancel-subscription/${subscriptionId}`;
  },
  GENERATE_PORTAL_SESSION: () => {
    return `subscription-management/generate-portal-session`;
  },
  VERIFY_EMAIL_BY_CODE: "user-manager/verify",
  RESEND_VERIFY_EMAIL_BY_CODE: "user-manager/resend-verify-email",
  SEND_EMAIL_FORGOT_PASSWORD_CODE: "user-manager/request-password-recovery",
  USERS: (companyId) => {
    return `${EC_AUTH_BE_V2()}company/${companyId}/users`;
  },
  CREATE_MERCHANT: `${EC_AUTH_BE_V2()}merchant`,
  EDIT_MERCHANT: (companyId) => `${EC_AUTH_BE_V2()}merchant/${companyId}`,
  GET_USER_BY_ID: (merhcantId) => {
    return `${EC_AUTH_BE_V2()}merchant/${merhcantId}/users`;
  },
  USER: (sub) => {
    return window.location.host.includes("localhost")
      ? `https://dev.eclear-solutions.com/ec-authorization-be/v1/user/${sub}`
      : `https://${window.location.host}/ec-authorization-be/v1/user/${sub}`;
  },
  CREATE_USER_INFO: (merchantId) => {
    return `${EC_AUTH_BE_V2()}merchant/${merchantId}/users`;
  },
};
